<template>
    <div>
      <Toolbar :handle-reset="resetForm" :handle-submit="onCreateAddon" />
      <AddonForm
        :errors="violations"
        :values="item"
        ref="createForm"
        refForm="createForm"
        :loading="isLoading"
        :parent="this.parent"
      />
      <Loading :visible="isLoading" />
    </div>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import { createHelpers } from "vuex-map-fields";
  import AddonForm from "../../components/addon/Form.vue";
  import Loading from "../../components/Loading";
  import Toolbar from "../../components/Toolbar";
  import AddonMixin from "../../mixins/AddonMixin";
  import CreateMixin from "../../mixins/CreateMixin";
  import { mapGetters } from "vuex";
  
  const servicePrefix = "Addon";
  
  const { mapFields } = createHelpers({
    getterType: "addon/getField",
    mutationType: "addon/updateField",
  });
  
  export default {
    name: "AddonCreate",
    servicePrefix,
    mixins: [AddonMixin, CreateMixin],
    components: {
      Loading,
      Toolbar,
      AddonForm,
    },
    data() {
      return {
        item: {},
        parent: {},
      };
    },
    computed: {
      ...mapFields(["error", "isLoading", "created", "deleted", "violations"]),
      ...mapGetters("addon", ["find"]),
    },
    methods: {
      ...mapActions("addon", {
        create: "create",
      }),
      onCreateAddon() {
        if (this.$route.params.parent) {
          this.$refs.createForm.item.parent = this.$route.params.parent;
        }
        this.onSendForm();
      },
    },
    created() {
      this.parent = this.$route.params.parent
      ? this.find(this.$route.params.parent)
      : {};
    },
  };
  </script>
  